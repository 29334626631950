@import "../../Sass/Variables.scss";

.partners {
  padding: 30px 0;
}

.partners__heading {
  margin: 0;
  margin-bottom: 30px;
  font-size: 48px;
  line-height: 56px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.partners__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.partner {
  position: relative;
  height: 220px;
  padding: 30px;
  box-shadow: rgba($color: $darkBlue, $alpha: 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  background-color: $white;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: $darkBlue, $alpha: 0.6);
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  &__inner {
    position: absolute;
    bottom: 50px;
    right: 50%;
    transform: translate(50%, 50%);
    z-index: 10;
    display: block;
    width: calc(100% - 60px);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease, bottom 0.4s ease;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--hover {
    .partner__inner {
      pointer-events: all;
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .partner__inner {
      bottom: 50%;
      opacity: 1;
    }
  }
}

.partner__image {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}

.partner__link {
  display: block;
  padding: 12px 16px;
  color: $darkBlue;
  background-color: $white;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
  opacity: 0.9;

  & + & {
    margin-top: 10px;
  }
}

.partner__loading {
  padding: 30px 0;
}

.partner__loading__load {
  padding: 10px 0;
}

.partner__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and(max-width: 1550px) {
  .partner {
    height: 160px;
    padding: 30px;
  }
}

@media only screen and(max-width: 1300px) {
  .partners__list {
    gap: 20px;
  }

  .partner {
    padding: 10px;
  }
}

@media only screen and(max-width: 1260px) {
  .partner__inner {
    width: calc(100% - 40px);
  }
}

@media only screen and(max-width: 1160px) {
  .partner__inner {
    width: calc(100% - 20px);
  }
}

@media only screen and(max-width: 1060px) {
  .partners__heading {
    text-align: center;
  }

  .partners__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .partner {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and(max-width: 1000px) {
  .partners__heading {
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 48px;
  }
}

@media only screen and(max-width: 900px) {
  .partners__heading {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and(max-width: 768px) {
  .partners__heading {
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and(max-width: 550px) {
  .partners {
    padding: 15px 0;
  }

  .partners__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
}

@media only screen and(max-width: 425px) {
  .partners__list {
    gap: 15px;
  }

  .partner {
    max-width: 100%;
  }
}

@media only screen and(max-width: 375px) {
  .partners {
    padding: 10px 0;
  }

  .partners__list {
    gap: 10px;
  }
}
