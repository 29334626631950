@import "../../Sass/Variables.scss";

.hero {
  box-sizing: content-box;
  position: relative;
  // height: calc(100vh - 118px) // header with test;
  height: calc(100vh - 100px); // header no test;
  margin-top: -50px;
  padding-top: 50px;
  color: $white;
  background-color: $darkBlue;
}

.hero__slider {
  height: inherit;
}

.hero__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $darkBlue;
    opacity: 0.8;
  }

  .container {
    position: relative;
    z-index: 9;
  }
}

.hero__pagination__number {
  font-size: 32px;
  font-weight: 300;

  &:last-child {
    opacity: 0.6;
  }
}

.hero__pagination__drop {
  font-size: 32px;
  font-weight: 300;
}

.hero__heading {
  max-width: 1000px;
  margin: 0 auto;
  // margin-bottom: 12px;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
}

.hero__link {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: $white;
  font-weight: 500;
  text-decoration: none;

  &::first-letter {
    text-transform: uppercase;
  }
}

@media only screen and(max-width: 1200px) {
  .hero__heading {
    max-width: 900px;
  }
}

@media only screen and(max-width: 1000px) {
  .hero {
    // height: calc(100vh - 88px); // header with test
    height: calc(100vh - 70px); // header no test
  }

  .hero__heading {
    max-width: 700px;
    font-size: 42px;
    line-height: 48px;
  }

  .hero__description {
    max-width: 700px;
  }
}

@media only screen and(max-width: 930px) {
  .hero__slide {
    &__video {
      width: auto;
      height: 100%;
    }
  }
}

@media only screen and(max-width: 900px) {
  .hero__heading {
    max-width: 600px;
    font-size: 36px;
    line-height: 42px;
  }

  .hero__description {
    font-size: 16px;
    max-width: 500px;
  }
}

@media only screen and(max-width: 768px) {
  .hero__heading {
    max-width: 500px;
    font-size: 28px;
    line-height: 36px;
  }

  .hero__description {
    font-size: 16px;
    max-width: 400px;
  }
}

@media only screen and(max-width: 550px) {
  .hero {
    // height: calc(100vh - 68px); // header with test
    height: calc(100vh - 50px); // header no test
  }

  .hero__heading {
    max-width: 400px;
    font-size: 24px;
    line-height: 28px;
  }

  .hero__description {
    font-size: 16px;
    max-width: 400px;
  }

  .hero__pagination__number {
    font-size: 24px;
  }

  .hero__pagination__drop {
    font-size: 24px;
  }
}
