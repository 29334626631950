html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Inter", "Roboto", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $darkBlue;
  background-color: $bgGray;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--admin {
    main {
      margin-top: 0;
      flex-grow: 1;
    }

  }
}

img,
svg {
  display: block;
}

main {
  // margin-top: 118px; // header with test
  margin-top: 100px; // header no test
  flex-grow: 1;
}

.container {
  max-width: calc(100vw - 200px);
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
}

@media only screen and(max-width: 1600px) {
  .container {
    max-width: 100vw;
    padding: 0 20px;
  }
}

@media only screen and(max-width: 1000px) {
  main {
    margin-top: 88px; // header with test
    margin-top: 70px; // header no test
  }
}

@media only screen and(max-width: 550px) {
  main {
    margin-top: 68px; // header with test
    margin-top: 50px; // header no test
  }
}

@media only screen and(max-width: 425px) {
  .container {
    max-width: 100vw;
    padding: 0 15px;
  }
}

@media only screen and(max-width: 375px) {
  .container {
    padding: 0 10px;
  }
}
