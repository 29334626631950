@import "../../../Sass/Variables.scss";

.news {
  padding-top: 30px;
  padding-bottom: 30px;
}

.news__inner {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.news__image {
  max-width: 100%;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  object-fit: cover;
}

.news__heading {
  font-size: 32px;
  line-height: 48px;
  margin-top: 0;
  margin-bottom: 30px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.news__description {
  margin: 0;
  margin-bottom: 16px;
  overflow-x: auto;
  font-size: 20px;
  line-height: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.news__loading {
  padding: 30px 0;
}

.news__loading__load {
  padding: 10px 0;
}

.news__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and(max-width: 1000px) {
  .news {
    padding: 16px 0;
  }

  .news__heading {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 46px;
  }

  .news__description {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and(max-width: 900px) {
  .news__heading {
    font-size: 32px;
    line-height: 38px;
  }
}

@media only screen and(max-width: 768px) {
  .news__heading {
    font-size: 26px;
    line-height: 32px;
  }

  .news__description {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }

  .news__image {
    height: auto;
  }
}

@media only screen and(max-width: 550px) {
  .news {
    padding: 15px 0;
  }

  .news__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
  }
}

@media only screen and(max-width: 475px) {
  .news__heading {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and(max-width: 400px) {
  .news__heading {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and(max-width: 375px) {
  .news {
    padding: 10px 0;
  }
}
