@import "../../../Sass/Variables.scss";

.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 100px);
  padding: 60px 0;
}

.contact__title {
  margin: 0;
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 36px;
  color: $darkBlue;

  &::first-letter {
    text-transform: uppercase;
  }
}

.contact__text {
  max-width: 700px;
  width: 100%;
  margin: 0;
  color: $darkBlue;
  font-size: 22px;
  line-height: 30px;
}

.contact__img {
  max-width: 650px;
  width: 100%;
  height: auto;
  object-fit: cover;
}

// .contact__map {
//   display: block;
//   width: 100%;
//   margin: 0 auto;
// }

@media only screen and(max-width:1000px) {
  .contact {
    height: calc(100vh - 70px);
  }
}

@media only screen and(max-width:992px) {
  .contact {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .contact__img {
    max-width: 500px;
    width: 100%;
    height: auto;
  }

  .contact__left {
    text-align: center;
  }
}

@media only screen and(max-width:550px) {
  .contact {
    height: unset;
  }

  .contact__img {
    margin-top: 50px;
  }
}

@media only screen and (max-height: 850px) {
  .contact {
    height: unset;
  }
}
