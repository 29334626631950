@import "../../Sass/Variables.scss";

.nav__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav__item {
  position: relative;
  padding: 10px 0;

  & + & {
    margin-left: 60px;
  }
}

.nav__link {
  display: block;
  padding: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: $gray;
  text-decoration: none;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.25s ease, color 0.2s ease;

  &:hover {
    opacity: 1;
    color: $accent;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: 600;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &--active {
    opacity: 1;
    color: $accent;
    font-weight: 700;
  }
}

.nav__dropdown {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
}

.nav__item:hover {
  .nav__dropdown {
    opacity: 1;
    pointer-events: all;
  }
}

@media only screen and(max-width: 1100px) {
  .nav__item {
    & + & {
      margin-left: 40px;
    }
  }
}

@media only screen and(max-width: 1000px) {
  .nav {
    position: absolute;
    z-index: 9;
    // top: 88px; // header with test
    top: 70px; // header no test
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    background-color: rgba($color: $darkBlue, $alpha: 0.8);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease;
    overflow: auto;
  }

  .nav__inner {
    padding-bottom: 10px;
    background-color: $lightBlue;
    overflow: auto;
  }

  .nav--open {
    height: 100vh;
    pointer-events: all;
    opacity: 1;

    .nav__dropdown {
      pointer-events: all;
    }
  }

  .nav__list {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    padding: 10px 20px;
    overflow: hidden;
    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
  }

  .nav__item {
    display: block;
    width: 100%;
    padding: 10px 0;

    & + & {
      margin: 0;
    }
  }

  .nav__link {
    display: block;
    text-align: left;

    &::before {
      content: none;
    }
  }

  .nav__dropdown {
    position: unset;
    bottom: unset;
    right: unset;
    transform: unset;
    opacity: 1;
    pointer-events: none;
  }

  .nav__socials {
    justify-content: center;
  }
}

@media only screen and(max-width: 550px) {
  .nav {
    top: 68px; // header with test
    top: 50px; // header no test
  }
}

@media only screen and(max-width: 425px) {
  .nav__list {
    padding: 10px 15px;
  }
}

@media only screen and(max-width: 375px) {
  .nav__list {
    padding: 10px;
  }
}
