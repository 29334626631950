@import "../../Sass/Variables.scss";

.services {
  padding: 30px 0;
}

.services__heading {
  margin: 0;
  margin-bottom: 30px;
  font-size: 48px;
  line-height: 56px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.services__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.service {
  position: relative;
  box-shadow: rgba($color: $darkBlue, $alpha: 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  background-size: 115%;
  background-position: center;
  overflow: hidden;
  transition: background-size 0.25s ease;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $darkBlue;
    opacity: 0.6;
    pointer-events: none;
    transition: all 0.25s ease;
  }

  &:hover {
    background-size: 140%;
    &:after{
      opacity: 0.8;
    }
    
  }
}

.service__link {
  display: block;
  padding: 60px 30px;
  text-decoration: none;
  color: white;
}

.service__heading {
  position: relative;
  z-index: 9;
  margin: 0;
  font-size: 20px;
  line-height: 100px;
  text-align: center;
  text-transform: uppercase;
}

@media only screen and(max-width: 1550px) {
  .service__link {
    padding: 30px;
  }
}

@media only screen and(max-width: 1300px) {
  .services__list {
    gap: 20px;
  }
}

@media only screen and(max-width: 1260px) {
  .service__inner {
    width: calc(100% - 40px);
  }
}

@media only screen and(max-width: 1160px) {
  .service__inner {
    width: calc(100% - 20px);
  }
}

@media only screen and(max-width: 1060px) {
  .services__heading {
    text-align: center;
  }

  .services__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .service {
    max-width: 300px;
    width: 100%;
  }
}

@media only screen and(max-width: 1000px) {
  .services__heading {
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 48px;
  }
}

@media only screen and(max-width: 900px) {
  .services__heading {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and(max-width: 768px) {
  .services__heading {
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and(max-width: 550px) {
  .services {
    padding: 15px 0;
  }

  .services__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
}

@media only screen and(max-width: 425px) {
  .services__list {
    gap: 15px;
  }

  .service {
    max-width: 100%;
  }
}

@media only screen and(max-width: 375px) {
  .services {
    padding: 10px 0;
  }

  .services__list {
    gap: 10px;
  }
}
