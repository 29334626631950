@import "../../Sass/Variables.scss";

.footer {
  color: $white;
}

.footer__top {
  padding: 100px 0;
  background-color: $darkBlue;
}

.footer__bottom {
  padding: 16px 0;
  background-color: $lightBlue;

  .container {
    display: flex;
    justify-content: space-between;
    align-content: inherit;
  }
}

.footer__wrapper {
  display: flex;
  align-items: flex-start;
}

.footer__inner__heading {
  display: block;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 26px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.footer__link {
  display: block;
  margin-bottom: 32px;
}

.footer__inner {
  &:first-child {
    margin-right: 300px;
  }

  &:nth-child(2) {
    margin-right: 300px;
  }

  &:nth-child(3) {
    margin-right: auto;
  }
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__item {
  padding: 10px 0;
  font-size: 18px;
  line-height: 25px;
}

.footer__item__link {
  display: block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
  transition: color 0.25s ease;

  &:hover {
    color: $accent;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.footer__item__link--active {
  font-weight: 700;
  color: $accent;
}

.footer__work-time {
  display: flex;
  align-items: center;
}

.footer__work-time__circle {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;

  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid $white;
    border-radius: 10px;
  }
}

.footer__work-time {
  width: 200px;

  &:nth-child(6) {
    .footer__work-time__circle {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 10px;
        height: 20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: $white;
      }
    }
  }

  &:nth-child(7) {
    .footer__work-time__circle {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: $white;
      }
    }
  }
}

.footer__bottom__copyright {
  font-size: 16px;
  line-height: 18px;
  color: $white;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__bottom__phone {
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
  color: $white;
}

.footer__work-time__short {
  max-width: 35px;
  width: 100%;
  margin-right: 10px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.footer__work-time__time {
  &::first-letter {
    text-transform: uppercase;
  }
}

.footer__socials {
  padding-top: 10px;
}

@media only screen and(max-width: 1900px) {
  .footer__inner {
    &:first-child {
      margin-right: 200px;
    }

    &:nth-child(2) {
      margin-right: 200px;
    }

    &:nth-child(3) {
      margin-right: auto;
    }
  }
}

@media only screen and(max-width: 1650px) {
  .footer__inner {
    &:first-child {
      margin-right: 150px;
    }

    &:nth-child(2) {
      margin-right: 150px;
    }

    &:nth-child(3) {
      margin-right: auto;
    }
  }
}

@media only screen and(max-width: 1200px) {
  .footer__top {
    padding: 60px 0;
  }

  .footer__wrapper {
    justify-content: space-between;
  }

  .footer__inner {
    &:first-child {
      margin-right: 0px;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-right: 0;
    }
  }
}

@media only screen and(max-width: 920px) {
  .footer__top {
    padding: 40px 0;
  }

  .footer__wrapper {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footer__inner {
    &:first-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      width: 100%;

      .footer__inner__heading {
        display: none;
      }
    }
  }

  .footer__socials {
    justify-content: center;
    margin-top: 30px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 650px) {
  .footer__inner:nth-child(3) {
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .footer__item {
    margin: 0 auto;
  }
}

@media only screen and(max-width: 550px) {
  .footer__link {
    svg {
      width: 150px;
    }
  }

  .footer__item {
    text-align: center;
  }
}

@media only screen and(max-width: 425px) {
  .footer__wrapper {
    justify-content: space-around;
  }

  .footer__inner__heading {
    text-align: center;
  }
}

@media only screen and(max-width: 375px) {
  .footer__top {
    padding: 30px 0;
  }
}
