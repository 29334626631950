@import "../../Sass/Variables.scss";

.inputs {
  padding: 30px 0 60px 0;
  background-color: $bgGray;
}

.input__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: $darkBlue;
  text-align: center;
  font-size: 46px;
  font-weight: 700;
}

.inputs__inner {
  display: inline-block;
  padding: 16px 24px;
  margin-bottom: 20px;
  max-width: 550px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  color: #838d98;
}

.input {
  width: 100%;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  border: none;
  outline: none;
  resize: none;

  &::-webkit-scrollbar{
    width: 0;
  }
}

.input::placeholder {
  color: $darkBlue;
  font-size: 18px;
  line-height: 24px;
}

.inputs__flex__inner {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 16px 24px;
  max-width: 550px;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: $darkBlue;
  color: $white;
  cursor: pointer;
}
@media screen and (max-width: 567px) {
  .title {
    font-size: 32px;
  }
}
