// Inter
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-300.woff2") format("woff2"),
    url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-300.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-400.woff2") format("woff2"),
    url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-400.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-500.woff2") format("woff2"),
    url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-500.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-600.woff2") format("woff2"),
    url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-600.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-700.woff2") format("woff2"),
    url("../Assets/Fonts/Inter/inter-v12-latin_cyrillic-700.woff") format("woff");
}

// Roboto
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-300.woff2") format("woff2"),
    url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-300.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-400.woff2") format("woff2"),
    url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-400.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-500.woff2") format("woff2"),
    url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-500.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-700.woff2") format("woff2"),
    url("../Assets/Fonts/Roboto/roboto-v30-latin_cyrillic-700.woff") format("woff");
}
