@import "../../Sass/Variables.scss";

.swiper-button-prev,
.swiper-button-next {
  color: $white;
}

.swiper-button-prev {
  left: 100px;
}

.swiper-button-next {
  right: 100px;
}

.swiper-pagination {
  bottom: 30px;
  font-weight: 300;
}

@media only screen and(max-width: 1280px) {
  .swiper-button-prev {
    left: 20px;
  }

  .swiper-button-next {
    right: 20px;
  }
}

@media only screen and(max-width: 550px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 10px;

    &::after {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .swiper-pagination {
    bottom: 16px;
  }
}

@media only screen and(max-width: 425px) {
  .swiper-button-prev {
    left: 15px;
  }

  .swiper-button-next {
    right: 15px;
  }
}

@media only screen and(max-width: 375px) {
  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }
}
