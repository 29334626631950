@import "../../../Sass/Variables.scss";

.about {
  padding-top: 30px;
  padding-bottom: 30px;
}

.about__heading {
  font-size: 48px;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: 30px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.about__description {
  margin-top: 0;
  margin-bottom: 24px;
  overflow-x: auto;
  font-size: 24px;
  line-height: 38px;

  &:last-child {
    margin-bottom: 0;
  }
}

.about__loading {
  padding: 30px 0;
}

.about__loading__load {
  padding: 10px 0;
}

.about__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and(max-width: 1000px) {
  .about {
    padding: 16px 0;
  }

  .about__heading {
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 48px;
  }

  .about__description {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 32px;
  }
}

@media only screen and(max-width: 900px) {
  .about__heading {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and(max-width: 768px) {
  .about__heading {
    font-size: 28px;
    line-height: 36px;
  }

  .about__description {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and(max-width: 550px) {
  .about {
    padding: 15px 0;
  }

  .about__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .about__description {
    text-align: center;
  }
}

@media only screen and(max-width: 375px) {
  .about {
    padding: 10px 0;
  }
}
