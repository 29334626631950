.post {
  padding: 100px 0;

  .post__button {
    max-width: 100px;
    width: 100%;
    margin-bottom: 30px;
  }
}

.post__modal__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  span {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .post__modal__button {
    display: block;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
  }
}

.post__form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.post__form__label {
  margin-right: 30px;
}

.post__form__inner {
  position: relative;
  width: 540px;
  height: 540px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;

  &--image {
    .post__form__wrapper {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.post__form__image {
  object-fit: contain;
}

.post__form__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: rgba($color: #000000, $alpha: 0.8);
  cursor: pointer;
  transition: opacity 0.25s ease;

  svg {
    margin-bottom: 20px;
  }

  span {
    font-size: 20px;
    line-height: 40px;
  }

  &:hover {
    opacity: 1;
  }
}

.post__form__aside {
  max-width: 550px;
  width: 100%;

  .post__form__button {
    height: 56px;
    margin-top: 50px;
  }
}

.post__form__input {
  text-transform: capitalize;

  &:first-child {
    margin-bottom: 30px;
  }
}

.post__form__area {
  max-width: 100%;
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  height: 100%;
  margin-bottom: 30px;
}
