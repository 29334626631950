@import "../../../Sass/Variables.scss";

.phone {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  width: 60px;
  height: 60px;
}

.phone__link {
  position: relative;
}

.phone__icon {
  position: absolute;
  z-index: 9;
  display: block;
  padding: 20px;
  border-radius: 50%;
  background-color: $accent;
  color: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  svg {
    position: relative;
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }
}

.phone__number {
  position: absolute;
  overflow: hidden;
  top: 10px;
  right: -50px;
  display: block;
  width: 40px;
  height: 40px;
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  color: $white;
  border-radius: 5px;
  background-color: $accent;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: width 0.5s ease, right 0.5s ease;
}

.phone--hover {
  .phone__icon {
    svg {
      transform: rotate(360deg);
    }
  }

  .phone__number {
    right: -20px;
    width: 180px;
  }
}

@media only screen and(max-width: 1024px) {
  .phone {
    bottom: 30px;
    right: 30px;
  }
}

@media only screen and(max-width: 768px) {
  .phone {
    bottom: 20px;
    right: 20px;
  }
}
