.product {
  padding: 100px 0;
}

.product__form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.product__form__label {
  margin-right: 30px;
}

.product__form__inner {
  position: relative;
  width: 540px;
  height: 540px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;

  &--image {
    .product__form__wrapper {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.product__form__image {
  object-fit: contain;
}

.product__form__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: rgba($color: #000000, $alpha: 0.8);
  cursor: pointer;
  transition: opacity 0.25s ease;

  svg {
    margin-bottom: 20px;
  }

  span {
    font-size: 20px;
    line-height: 40px;
  }

  &:hover {
    opacity: 1;
  }
}

.product__form__aside {
  max-width: 550px;
  width: 100%;

  .product__form__button {
    height: 56px;
    margin-top: 100px;
  }
}

.product__form__input {
  text-transform: capitalize;

  & + & {
    margin-top: 30px;
  }
}

.product__params {
  margin: 0;
  margin-bottom: 30px;
  padding: 0;
  list-style-type: none;
}

.product__param {
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 30px;
  }
}

.product {
  .product__param__input {
    max-width: 300px;
    width: 100%;
    margin-right: 30px;

    &:nth-child(2) {
      max-width: 600px;
    }
  }

  .products__bottom__button {
    display: flex;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.product__modal__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;

  span {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }

  .product__modal__button {
    display: block;
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
  }
}
