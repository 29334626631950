@import "../../../Sass/Variables.scss";

.products {
  padding: 30px 0;
}

.products__heading {
  margin: 0;
  margin-bottom: 30px;
  font-size: 48px;
  line-height: 56px;

  span {
    text-transform: uppercase;
  }
}

.product__list {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.product__item {
  position: relative;
  max-width: 100%;
  min-height: 460px;
  background-color: $white;
  border-radius: 5px;
  box-shadow: rgba($color: $darkBlue, $alpha: 0.2) 0px 2px 8px 0px;

  &:hover {
    &::after {
      opacity: 0.7;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $darkBlue;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease;
  }
}

.product__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product__wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  overflow: hidden;
}

.product__image {
  max-width: 100%;
  width: 100%;
  max-height: 400px;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.product__title {
  max-width: 350px;
  height: 72px;
  margin: 0 auto;
  padding: 12px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
}

.product__options {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 0%);
  z-index: 9;
  max-width: 300px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: $white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease, transform 0.25s ease;
}

.product__option {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  & + & {
    margin-top: 12px;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.product__item {
  &:hover {
    .product__options {
      pointer-events: all;
      transform: translate(50%, -50%);
      opacity: 1;
    }
  }
}

.products__loading {
  padding: 30px 0;
}

.products__loading__load {
  padding: 10px 0;
}

.products__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and(max-width: 1700px) {
  .products {
    padding: 20px 0;
  }

  .product__list {
    gap: 20px;
  }
}

@media only screen and(max-width: 1650px) {
  .product__title {
    height: 64px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and(max-width: 1310px) {
  .product__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and(max-width: 1000px) {
  .products {
    padding: 16px 0;
  }

  .products__heading {
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 48px;
  }
}

@media only screen and(max-width: 900px) {
  .products__heading {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and(max-width: 970px) {
  .product__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and(max-width: 768px) {
  .products__heading {
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and(max-width: 670px) {
  .product__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and(max-width: 550px) {
  .products {
    padding: 15px 0;
  }

  .products__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
}

@media only screen and(max-width: 425px) {
  .products {
    padding: 15px 0;
  }

  .product__list {
    gap: 15px;
  }
}

@media only screen and(max-width: 375px) {
  .products {
    padding: 10px 0;
  }

  .product__list {
    gap: 10px;
  }
}
