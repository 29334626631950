@import "../../../Sass/Variables.scss";

.services {
  padding: 30px 0;
}

.service__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service__poster {
  max-width: 850px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.service__title {
  margin: 0;
  margin-bottom: 30px;
  font-size: 30px;
}

.service__text {
  margin: 0;
  max-width: 600px;
  width: 100%;
  font-size: 24px;
}

.service__loading {
  padding: 30px 0;
}

.service__loading__load {
  padding: 10px 0;
}

.service__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and(max-width: 1280px) {
  .service__inner {
    flex-direction: column;
  }

  .service__poster {
    max-width: 1220px;
    width: 100%;
    margin-bottom: 30px;
  }

  .service__title {
    text-align: center;
  }

  .service__text {
    font-size: 26px;
    text-align: center;
    margin: 0;
    max-width: 1000px;
    width: 100%;
  }
}

@media only screen and(max-width: 550px) {
  .services {
    padding: 15px 0;
  }

  .service__poster {
    margin-bottom: 20px;
  }

  .service__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .service__text {
    font-size: 18px;
  }
}

@media only screen and(max-width: 550px) {
  .services {
    padding: 10px 0;
  }

  .service__poster {
    margin-bottom: 10px;
  }
}
