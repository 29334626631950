@import "../../Sass/Variables.scss";

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: $darkBlue;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: padding 0.25s ease;
  }

  &--small {
    .container {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.header__test {
  width: 200%;
  display: flex;
  align-items: center;
  background-color: $accent;
  padding: 2px 0;
  font-size: 12px;
  line-height: 14px;

  &__inner {
    width: 100%;
    display: flex;
    justify-content: space-around;

    &:nth-child(1) {
      -webkit-animation: scrolling-left1 30s linear infinite;
      animation: scrolling-left1 30s linear infinite;
    }

    &:nth-child(2) {
      -webkit-animation: scrolling-left2 30s linear infinite;
      animation: scrolling-left2 30s linear infinite;
      animation-delay: 15s;
    }
  }

  span {
    display: inline-block;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

@keyframes scrolling-left1 {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scrolling-left2 {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

@-webkit-keyframes scrolling-left1 {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

.header__link {
  display: block;
}

.header__languages {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header__language {
  & + & {
    margin-left: 20px;
  }

  &--active {
    .header__language__button {
      color: $accent;
      opacity: 1;

      &:hover {
        color: $accent;
      }
    }
  }
}

.header__language__button {
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: color 0.25s ease;

  &:hover {
    color: $white;
  }
}

@media only screen and(max-width: 1000px) {
  .header {
    .container {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .header__languages {
    position: absolute;
    top: 68px;
    right: 68px;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    box-shadow: $gray 0px 0px 0px 0.7px, rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    background-color: $white;
    transition: opacity 0.25s ease;

    &--open {
      opacity: 1;
      pointer-events: all;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 6px solid $white;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      display: inline-block;
      width: 0;
      height: 0;
      border-bottom: 7px solid $gray;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }
  }

  .header__lang__curtain {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
  }

  .header__language {
    padding: 5px 20px;
    color: $black;
    opacity: 0.7;

    &__button {
      color: $black;
    }

    &--active {
      display: none;
      padding: 0;
      border-bottom: none;
    }

    & + & {
      margin: 0;
    }
  }

  .header__active__lang {
    margin-left: auto;
    margin-right: 30px;
    color: $gray;

    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 5px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  .header__burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 0;
    color: $white;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

@media only screen and(max-width: 550px) {
  .header {
    .container {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .header__link {
    svg {
      width: 150px;
    }
  }

  .header__active__lang {
    margin-right: 20px;
  }

  .header__languages {
    top: 58px;
    right: 58px;
  }
}

@media only screen and(max-width: 425px) {
  .header__languages {
    right: 53px;
  }
}

@media only screen and(max-width: 425px) {
  .header__languages {
    right: 48px;
  }
}
