@import "../../Sass/Variables.scss";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $darkBlue, $alpha: 0.6);
}

.modal__inner {
  position: relative;
  max-width: 550px;
  width: 100%;
  height: 550px;
  background-color: $white;
  border-radius: 20px;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  padding: 15px;
  color: $white;
  border: none;
  border-radius: 50%;
  background-color: $lightBlue;
  cursor: pointer;
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  padding-top: 130px;
  padding-bottom: 75px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 35px;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
  }

  p {
    flex-grow: 1;
    margin: 0;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
  }
}

.modal__button {
  padding: 8px 35px;
  font-size: 24px;
  line-height: 35px;
  border: none;
  border-radius: 5px;
  color: $white;
  background-color: $lightBlue;
  cursor: pointer;
}

@media only screen and (max-height: 800px) {
  .modal__inner {
    max-width: 550px;
    width: 100%;
    height: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .modal__inner {
    max-width: 400px;
    width: 100%;
  }

  .modal__close {
    padding: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .modal__wrapper {
    padding-top: 75px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 28px;
    }

    p {
      font-size: 20px;
      line-height: 18px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal__inner {
    max-width: calc(100% - 40px);
    width: 100%;
  }

  .modal__close {
    padding: 10px;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .modal__wrapper {
    padding-top: 50px;
    padding-bottom: 50px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 25px;
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .modal__button {
    padding: 8px 35px;
    font-size: 18px;
    line-height: 24px;
  }
}
