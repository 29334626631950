@import "../../Sass/Variables.scss";

.news {
  padding-top: 30px;
  padding-bottom: 30px;
}

.news__heading {
  font-size: 48px;
  line-height: 56px;
  margin-top: 0;
  margin-bottom: 30px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.news__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.news__item {
  border-radius: 5px;
  background-color: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;
}

.news__item__inner {
  padding: 16px;
}

.news__item__image {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.news__item__title {
  height: 48px;
  margin: 0;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;

  &::first-letter {
    text-transform: uppercase;
  }
}

.news__item__text {
  height: 100px;
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;

  &::first-letter {
    text-transform: uppercase;
  }
}

.news__item__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news__item__link {
  color: $accent;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.news__loading {
  padding: 30px 0;
}

.news__loading__load {
  padding: 10px 0;
}

.news__loading__refresh {
  display: flex;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: $lightBlue;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $darkBlue;
  }
}

@media only screen and (max-width: 1500px) {
  .news__item__title {
    font-size: 18px;
  }
}

@media only screen and(max-width: 1350px) {
  .news__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and(max-width: 1300px) {
  .news__list {
    gap: 20px;
  }
}

@media only screen and(max-width: 1060px) {
  .news__heading {
    text-align: center;
  }
}

@media only screen and(max-width: 1000px) {
  .news__heading {
    margin-bottom: 16px;
    font-size: 42px;
    line-height: 48px;
  }

  .news__list {
    grid-template-columns: repeat(1, 1fr);
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and(max-width: 900px) {
  .news__heading {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and(max-width: 768px) {
  .news__heading {
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and(max-width: 550px) {
  .news__heading {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
}
