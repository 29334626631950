.users {
  padding: 100px 0;

  .users__button {
    max-width: 100px;
    width: 100%;
    margin-bottom: 30px;
  }
}

.users__top {
  margin-bottom: 16px;
}

.users__top__button {
  & + & {
    margin-left: 10px;
  }
}

.users__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.user {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 16px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  & + & {
    margin-top: 20px;
  }
}

.user__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
